import { createStore } from 'vuex'
import {app} from './main'
import {api} from './api'

const store = createStore({
  state: {
    current_user: {profile: {}},
    groups: [],
    helpModal: null,
    task: {},
    tasks: [],
    loading: false,
    userConfig: {},
    csrftoken: null,
    returnTo: null,
    webView: null,
    noSleep: null,
    demo: false,
    user: {profile: {}},
    auth: 'login',
    config: {
      theme: 'light',
    },
    cardsMode: 'categories',
    paints: [],
    fastCategory: null,
    sidebar: null,
    playlist: null,
    playlists: [],
    movies: [],
    streams: [],
    jettonsCount: 12,
    jettons: [],
    navModal: null,
    record_seqences: false,
    histories: [],
    sequences: [],
    setsPanelVisible: false,
    sets: [],
    set : {},
    list: [],
    categories: [],
    category: {},
    card: {},
    cards: [],
    voiceRecognizing: false,
    no_name: null,
    full_screen: false,
    audio: null,
    list_audio: null,

    // letter-cards start
    letter: '',
    letter_show: false,
    tree_cards: [],
    clickVariantEnable: false,
    // letter-cards end

    gameCards: [],
    gameCard: {},
    
    voiceText: null,
    voiceActions: {},
    voiceImages: [],

    setCategoriesCards: []
  },
  getters: {
    lastCardId (state) {
      if (state.list.length) {
        return state.list[state.list.length - 1].id
      } else {
        return null
      }
    },
  },
  mutations: {
    setUser (state, data) {
      state.user = data
    },
    setData (state, {attr, value}) {
      state[attr] = value
    },
    addData (state, {attr, value}) {
      state[attr].push(value)
    },
    removeItem (state, {attr, value}) {
      state[attr] = state[attr].filter(i => i.id !== value.id)
    },
    deleteCardFromList (state, card) {
      state.list = state.list.filter(c => c.id !== card.id)
    },
    createJettons (state) {
      state.jettons = []
      const jettonsCount = state.jettonsCount || 6
      for (let i = 0; i < jettonsCount; i++) {
        state.jettons.push({done: false})
      }
    },
    selectJetton (state, i) {
      if (state.jettons[i].done) {
        state.jettons[i].done = false
      } else {
        state.jettons[i].done = true
      }
    },
    selectJettonsCount (state, n) {
      while (n > 0) {
        const jetton = state.jettons.filter(j => !j.done)[0]
        if (jetton === undefined) {
          break
        }
        jetton.done = true
        n -= 1
      }
    },
    mergeStore (state, data) {
      for (const key of Object.keys(data)) {
        state[key] = data[key]
      }
    },
    changeTheme (state) {
      console.log('changeTheme', state.config.theme)
      if (state.config.theme === 'light') {
        state.config.theme = 'dark'
      } else {
        state.config.theme = 'light'
      }
      document.documentElement.setAttribute('data-bs-theme', state.config.theme)
      app.$socket.emit('set_config_attr', {
        attr: 'theme',
        value: state.config.theme
      })
    },
    SOCKET_merge_store (state, data) {
      for (const key of Object.keys(data)) {
        if (state.demo && key === 'config') {
          // В демо режиме конфиг с сервера не нужен
          console.log('SOCKET_merge_store', state.demo, key)
        } else if (key === 'set' && state.set.id !== data.set.id) {
          console.log('another set')
          break
        } else {
          state[key] = data[key]
          if (key === 'config') {
            if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
              console.log('Config theme not apply')
            } else {
              document.documentElement.setAttribute('data-bs-theme', data.config.theme)
            }
          }
        }
      }
      // app.$toast.info(`Обновлено`)
    },
    SOCKET_set_update (state, data) {
      if (state.set.id === data.set.id) {
        state.set = data.set
      }
      if (state.admin.set.id === data.set.id) {
        state.admin.set = data.set
      }
    },
    SOCKET_delete_item (state, data) {
      state[data.collection] = state[data.collection].filter(i => i.id !== data.id)
      app.$toast.info(`Удалено`)
    },
    SOCKET_delete_attachment (state, data) {
      state.admin.card.attachments = state.admin.card.attachments.filter(a => a.id !== data.id)
      app.$toast.info(`Удалено`)
    },
    SOCKET_add_item (state, data) {
      for (const key of Object.keys(data)) {
        state[key].unshift(data[key])
      }
    },
    SOCKET_add_stream (state, stream) {
      state.streams.push(stream)
    },
    SOCKET_append_item (state, data) {
      for (const key of Object.keys(data)) {
        state[key].push(data[key])
      }
    },
    SOCKET_update_item (state, data) {
      const item = state[data.collection].filter(i => i.id === data.id)[0]
      if (!item) return
      for (const key of Object.keys(data.item)) {
        item[key] = data.item[key]
      }
      app.$toast.info(`Обновлено`, {position: 'bottom'})
    },
    SOCKET_update_set_card (state, data) {
      if (state.set.id === data.set_id) {
        const card = state.set.cards.filter(c => c.id === data.card_id)[0]
        if (card) {
          card.unavailable_now = data.unavailable_now
          card.available_from = data.available_from
        }
      }
      if (state.admin.set.id === data.set_id) {
        const card = state.admin.set.cards.filter(c => c.id === data.card_id)[0]
        if (card) {
          card.unavailable_now = data.unavailable_now
          card.available_from = data.available_from
        }
      }
    },
  },
  actions: {
    getToken ({commit}) {
      const name = 'csrftoken'
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      commit('mergeStore', {csrftoken: cookieValue})
    },

    checkFullScreen ({state, commit}) {
      if (!state.full_screen) {
        if (!window.noSleep.enabled) {
          window.noSleep.enable();
        }
        const el = document.body;
        const rfs = el.requestFullscreen
                  || el.webkitRequestFullscreen
                  || el.mozRequestFullScreen
                  || el.msRequestFullscreen;
    
        if (rfs) {
          rfs.call(el);
        } else {
          console.error("Fullscreen API is not supported in this browser.");
        }
    
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        console.log(isTablet);
    
        if (isTablet) {
          if (screen.orientation && screen.orientation.lock) {
            screen.orientation.lock('landscape').catch(err => {
              console.error("Failed to lock screen orientation:", err);
            });
          } else {
            console.error("Screen Orientation API is not supported in this browser.");
          }
        }
    
        commit('mergeStore', {full_screen: true});
      } else {
        const exitFullscreen = document.exitFullscreen
                            || document.mozCancelFullScreen
                            || document.webkitExitFullscreen
                            || document.msExitFullscreen;
    
        if (exitFullscreen) {
          exitFullscreen.call(document);
        } else {
          console.error("Exit fullscreen API is not supported in this browser.");
        }
    
        commit('mergeStore', {full_screen: false});
      }
    },

    async signup (context) {
      try {
        const response = await api.signup(context)
        if (response.status === 200 && response.data.status === 'success') {
          // context.state.auth = 'login'
          app.$toast.success('Успешная регистрация!', {duration: 2000})
          setTimeout(() => {
            location.replace('/')
          }, 1000)
        } else {
          app.$toast.error(response.data.message)
        }
      } catch (e) {
        app.$toast.error(`${e.name}: ${e.message}`)
      }
    },

    async login (context) {
      try {
        const response = await api.login(context)
        if (response.status === 200 && response.data) {
          console.log('response.data', response.data, response);
          if (response.data.status === 'not auth') {
            app.$toast.error('Неверный логин или пароль', {position: 'top'})
          } else {
            // context.dispatch('getUser')
            setTimeout(() => {
              location.replace('/')
            }, 1000)
          }
        }
      } catch (e) {
        app.$toast.error(`${e.name}: ${e.message}`)
      }
    },

    async logout (context) {
      try {
        const response = await api.logout(context)
        console.log('logout', response.status)
        if (response.status === 200) {
          context.commit('mergeStore', {user: {}})
          location.reload()
        } 
      } catch (e) {
        app.$toast.error(`${e.name}: ${e.message}`)
      }
    },

    async getUser (context) {
      try {
        const response = await api.getMe(context.state)
        if (response.data && response.data.user) {
          context.commit('setUser', response.data.user)
          // app.$socket.emit('get_data', ['config'])
        }
      } catch (e) {
        app.$toast.error(`${e.name}: ${e.message}`)
      }
    },

    async voiceRecognize (context) {
      context.state.voiceRecognizing = true
      try {
        const response = await api.voiceRecognize(context)
        if (response.status === 200 && response.data) {
          console.log(response.data);
          // card.audio = response.data
          // context.commit('setData', {attr: 'card', value: card})
          app.$toast.info(`Распознано ${response.data.result}`)
          context.commit('setData', {attr: 'audioSource', value: null})
          return response.data.result
        }
      } catch (e) {
        // app.$toast.error(`${e.name}: ${e.message}`)
        app.$toast.error(`${e.type}: ${e.message}`)
      } finally {
        context.state.voiceRecognizing = false
      }
    },

    async text2speech (context) {
      try {
        const response = await api.text2speech(context)
        if (response.status === 200 && response.data) {
          const card = { ...context.state.card }
          card.synthesize_audio_url = response.data
          context.commit('setData', {attr: 'card', value: card})
          app.$toast.success(`Текст переведен в аудио`)
        }
      } catch (e) {
        app.$toast.error(`${e.type}: ${e.message}`)
      }
    },

    async getAudio (context, record_seq) {
      try {
        const response = await api.getAudio(context, record_seq)
        if (response.status === 200 && response.data) {
          context.commit('setData', {attr: 'list_audio', value: response.data})
        }
      } catch (e) {
        app.$toast.error(`${e.type}: ${e.message}`)
      }
    },

  }
})

store.registerModule('admin', {
  state: () => ({
    userConfig: {},
    user: {profile: {}},
    set: {},
    sets: [],
    category: {},
    categories: [],
    card: {},
    cards: [],
    cardMode: 'image',
    cardImageMode: 'upload',
    photos: {},
    photos_query: null,
    photos_page: 1,
    image_url: null,
    image_coordinates: null,

    setModal: null,
    categoryModal: null,
    category_cards: [],
    cardModal: null,
    file: null,

    audioSource: null,
    blob: null,

    setCategoriesCards: []
  }),
  mutations: {
    setUser (state, data) {
      state.user = data
    },
    SOCKET_merge_admin_store (state, data) {
      for (const key of Object.keys(data)) {
        state[key] = data[key]
      }
    },
    mergeAdminStore (state, data) {
      for (const key of Object.keys(data)) {
        state[key] = data[key]
      }
    },
    SOCKET_admin_update_item (state, data) {
      let item = state[data.collection].filter(i => i.id === data.id)[0]
      if (!item) {
        return
      }
      for (const key of Object.keys(data.item)) {
        item[key] = data.item[key]
      }
      app.$toast.info(`Обновлено`, {position: 'bottom'})
      if (state.categoryModal) {
        state.categoryModal.hide()
      }
      if (state.setModal) {
        state.setModal.hide()
      }
    },
    SOCKET_admin_add_item (state, data) {
      console.log('SOCKET_admin_add_item', state.categories, data)
      for (const key of Object.keys(data)) {
        state[key].unshift(data[key])
      }
      if (state.categoryModal) {
        state.categoryModal.hide()
      }
      if (state.setModal) {
        state.setModal.hide()
      }
    },
  },
  actions: {
    async saveImage (context) {
      try {
        const response = await api.saveImage(context)
        if (response.status === 200 && response.data) {
          console.log('response.data', response.data)
          const upd_card = response.data.card
          context.commit('mergeAdminStore', {
            card: upd_card,
            photos: {},
            image_url: null,
            cardImageMode: 'show'
          })
          const card = context.state.cards.filter(c => c.id === upd_card.id)[0]
          if (card) {
            card.thumb = upd_card.thumb
            card.image = upd_card.image
          }
          if (context.state.set && context.state.set.cards) {
            const set_card = context.state.set.cards.filter(c => c.id === upd_card.id)[0]
            if (set_card) {
              set_card.thumb = upd_card.thumb
              set_card.image = upd_card.image
            }
          }
        }
      } catch (e) {
        app.$toast.error(`${e.name}: ${e.message}`)
      }
    },

    async getData ({commit, state}, models) {
      try {
        const query = models ? models.join(',') : 'sets,set,categories,cards,sequences,setCategoriesCards'
        let url = `/api/data?query=${query}`
        console.log('state.user', state, state.user)
        // if (rootState.user.profile.role === 'admin' && rootState.user.profile.students) {
        //   url += `&user_id=${rootState.user.profile.students[0].user_id}`
        // }
        const response = await fetch(url)
        if (response.status === 200) {
          const data = await response.json()
          console.log('data', data)
          commit('mergeAdminStore', data)
        }
      } catch (e) {
        console.error(e)
        app.$toast.error(`${e.type}: ${e.message}`)
      }
    },

    async getPhotos (context) {
      try {
        const response = await api.getPhotos(context)
        if (response.status === 200 && response.data) {
          context.commit('mergeAdminStore', {
            photos: response.data,
            photos_query: response.data['text']
          })
        }
      } catch (e) {
        app.$toast.error(`${e.name}: ${e.message}`)
      }
    },

    async uploadAudio (context) {
      try {
        const response = await api.uploadAudio(context)
        if (response.status === 200 && response.data) {
          console.log(response.data);
          const card = context.state.card
          card.audio = response.data
          context.commit('mergeAdminStore', { card, audioSource: null})
          app.$toast.show(`Аудиозапись успешно загружена`)
          // app.$socket.emit('get_data', ['cards'], {})
          context.dispatch('getData', ['cards'])
        }
      } catch (e) {
        // app.$toast.error(`${e.name}: ${e.message}`)
        app.$toast.error(`${e.type}: ${e.message}`)
      }
    },

    async deleteCard ({ state, commit, dispatch }) {
      if (confirm('Удалить карточку?')) {
        try {
          const response = await api.deleteCard(state)
          if (response.status === 204) {
            commit('mergeAdminStore', {card: {}})
            app.$toast.success(`Карточка удалена`)
            state.cardModal.hide()
            // app.$socket.emit('get_data', ['cards'], {})
            dispatch('getData', ['cards', 'set'])
          }
        } catch (e) {
          app.$toast.error(`${e.type}: ${e.message}`)
        }
      }
    },

    async saveCardsOrder (context) {
      try {
        const response = await api.saveCardsOrder(context)
        if (response.status === 200 && response.data) {
          app.$toast.show(`Порядок карточек сохранен`)
        }
      } catch (e) {
        app.$toast.error(`${e.type}: ${e.message}`)
      }
    },

    async saveCard (context) {
      try {
        // app.$socket.emit('cards', state.card)
        const response = await api.saveCard(context)
        if (response.status === 200 && response.data) {
          console.log('response', response)
          context.dispatch('getData', ['cards'])
        }
      } catch (e) {
        app.$toast.error(`${e.type}: ${e.message}`)
      }
    },

    async getImageFromUrl (context) {
      try {
        const response = await api.getImageFromUrl(context)
        if (response.status === 200 && response.data) {
          context.commit('mergeAdminStore', {
            card: { ...context.state.card, image: response.data },
            cardImageMode: 'show'
          })
        } else {
          app.$toast.error(`Ошибка загрузки изображения. Попробуйте другое`)
        }
      } catch (e) {
        // app.$toast.error(`${e.type}: ${e.message}`)
        app.$toast.error(`Ошибка загрузки изображения. Попробуйте другое`)
      }
    },

    async uploadFile (context) {
      try {
        const response = await api.uploadFile(context)
        if (response.status === 200 && response.data) {
          context.commit('mergeAdminStore', {
            card: response.data.card,
            cardImageMode: 'show'
          })
        }
      } catch (e) {
        app.$toast.error(`${e.type}: ${e.message}`)
      }
    },

    async deleteCategory ({ state, commit, dispatch }) {
      if (state.user.username.includes('@demo.ru')) {
        return app.$toast.error('Для демо пользователя недоступно редактирование')
      }
      if (confirm('Удалить категорию?')) {
        try {
          const response = await api.deleteCategory(state)
          console.log('state.categoryModal', state.categoryModal);
          if (response.status === 204) {
            commit('setData', {attr: 'category', value: {}})
            app.$toast.success(`Категория удалена`)
            state.categoryModal.hide()
            dispatch('getData', ['categories', 'cards'])
            // app.$socket.emit('getData', ['categories', 'cards'])
          }
        } catch (e) {
          app.$toast.error(`${e.type}: ${e.message}`)
        }
      }
    },
  },
  getters: {
    cards (state) {
      if (state.set.cards) {
        // const setCardIds = this.$store.state.admin.set.cards.map(c => c.id)
        // return this.$store.state.admin.setCategoriesCards.filter(c => !setCardIds.includes(c.id))
        if (state.category.id) {
          return state.set.cards.filter(c => c.category_id === state.category.id)
        } else {
          // return state.cards.filter(c => state.set.cards.includes(c.id) && state.set.categories.includes(c.category_id))
          return state.set.cards
        }
      }
      if (state.category.id) {
        return state.cards.filter(c => c.category_id === state.category.id)
      }
      return state.cards
    },
  }
})

export {
  store
}
