<template>
  <div id="_app_" :style="{ height: containerHeight + 'px' }" class="">

    <nav 
      class="navbar fixed-top navbar-expand" 
      :class="{'bg-body-tertiary': $store.state.user.profile.role != 'student'}">
      <div class="container-fluid">
        <ul class="navbar-nav">
          <li v-if="$route.name != 'IndexPage'" class="nav-item">
            <button
              @click="back"
              class="nav-link bg-blur rounded">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-grid-fill" viewBox="0 0 16 16">
                <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5z"/>
              </svg>
            </button>
          </li>
          <li 
            v-if="current_user.is_staff"
            class="nav-item">
            <button
              class="nav-link bg-blur rounded"
              data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
              </svg>
            </button>
          </li>
        </ul>

        <ul class="navbar-nav" v-if="$route.name === 'DemoPage'">
          <li class="nav-item">
            <button
              class="nav-link bg-blur rounded"
              data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
              </svg>
            </button>
          </li>
        </ul>

        <ul class="navbar-nav" v-if="$store.state.user.profile.role != 'student'">
          <li class="nav-item" v-if="userPages.includes($route.name)">
            <router-link 
              :to="{name: 'UserPage', params: {id: $route.params.id}}"
              class="nav-link bg-blur rounded">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
              </svg>
            </router-link>
          </li>
          <li class="nav-item ms-1" v-if="['UserSetPage', 'UserTaskPage'].includes($route.name)">
            <router-link 
              :to="{name: 'UserSetManagePage', params: {id: $route.params.id, set_id: $route.params.set_id}}"
              class="nav-link rounded bg-blur">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-chat-square-dots mt-1" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </router-link>
          </li>
          <li class="nav-item ms-1" v-else-if="['UserCardsAddPage', 'UserCardsPage'].includes($route.name)">
            <router-link 
              :to="{name: 'UserCardsManagePage', params: {id: $route.params.id}}"
              class="nav-link rounded bg-blur">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-chat-square-dots mt-1" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </router-link>
          </li>
        </ul>

        <ul class="navbar-nav ms-auto" v-if="!$store.state.webView">
          <li class="nav-link pt-2">
            <div 
              v-if="!$store.state.user.id || $store.state.user.username === 'demo@demo.ru'" 
              class="telegram-login-button" 
              ref="telegramLoginButton">
            </div>
          </li>
          <li class="nav-item" v-if="instructions.length">
            <button
              class="nav-link"
              :class="{'bg-blur rounded': $store.state.webView}"
              @click="clickHelp">
              <!-- data-bs-toggle="offcanvas" data-bs-target="#offcanvasHelp" aria-controls="offcanvasHelp" -->
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-patch-question" viewBox="0 0 16 16">
                <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745"/>
                <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
                <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
              </svg>
            </button>
          </li>
          <li class="nav-item dropdown me-n2">
            <a class="nav-link dropdown-toggle bg-blur rounded" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
              </svg>
            </a>
            <ul class="dropdown-menu dropdown-menu-end text-center" style="--bs-dropdown-min-width: 1rem;">
              <li 
                v-if="$store.state.user.id && $store.state.user.profile.role !== 'student'"
                class="mb-2">
                <router-link 
                  :to="{name: 'UserPage', params: {id: $store.state.user.id}}"
                  class="dropdown-item">
                  <img 
                    v-if="$store.state.user.profile.photo_url" 
                    :src="$store.state.user.profile.photo_url"
                    width="28" height="28"
                    class="rounded-circle">
                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-person-circle opacity-50" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                    <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                  </svg>
                </router-link>
              </li>
              <li class="mb-2">
                <ThemeChangeComponent class="dropdown-item"/>
              </li>
              <li class="mb-2">
                <button
                  @click="fullScreenChange"
                  class="dropdown-item text-secondary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
                    <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
                  </svg>
                </button>
              </li>
              <li v-if="showRefresh">
                <button
                  class="dropdown-item"
                  @click="refreshApp"
                  data-bs-toggle="modal" data-bs-target="#refreshModal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-repeat text-primary" viewBox="0 0 16 16">
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
                  </svg>
                </button>
              </li>
              <li v-if="$store.state.user.id">
                <button @click="logout" class="dropdown-item text-secondary">
                  Выход
                </button>
              </li>
            </ul>
          </li>
        </ul>
        
        <ul class="navbar-nav ms-auto" v-else>
          <!-- <li class="nav-item">
            <button
              class="nav-link"
              :class="{'bg-blur rounded': $store.state.webView}">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-patch-question" viewBox="0 0 16 16">
                <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745"/>
                <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
                <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
              </svg>
            </button>
          </li> -->
          <li class="nav-item" v-if="instructions.length">
            <button
              class="nav-link"
              :class="{'bg-blur rounded': $store.state.webView}"
              @click="clickHelp">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-patch-question" viewBox="0 0 16 16">
                <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745"/>
                <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
                <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
              </svg>
            </button>
          </li>
          <li class="nav-item">
            <router-link 
              :to="{name: 'UserPage', params: {id: $store.state.user.id}}"
              v-if="$store.state.user.id && $store.state.user.profile.role !== 'student'"
              class="nav-link"
              :class="{'bg-blur rounded': $store.state.webView}">
              <img 
                v-if="$store.state.user.profile.photo_url" 
                :src="$store.state.user.profile.photo_url"
                width="28" height="28"
                class="rounded-circle">
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-person-circle opacity-50" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
              </svg>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <router-view />

    <div v-if="$store.state.loading" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>

    <div class="modal" id="helpModal" tabindex="-1" ref="helpModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Демонстрация</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pb-0" v-if="showHelpModal">
            <div v-for="item in instructions" :key="item.id" :id="'item-' + item.id" class="mb-4">
              <div class="d-flex align-items-center mb-3">
                <h4 class="mb-0">
                  {{ item.name }}
                </h4>
                <router-link v-if="$store.state.user.is_staff" :to="{name: 'TutorialPage', params: {id: item.id}}" class="btn btn-sm btn-outline-secondary ms-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-input-cursor-text" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.17 4.17 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.5 3.5 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.17 4.17 0 0 1-2.06-.566A5 5 0 0 1 8 13.65a5 5 0 0 1-.44.285 4.17 4.17 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.5 3.5 0 0 0-.436-.294A3.17 3.17 0 0 0 5.5 2.5.5.5 0 0 1 5 2"/>
                    <path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1z"/>
                  </svg>
                </router-link>
              </div>
              <div v-html="item.content" class="mb-4"></div>
              <div class="border border-2 rounded">
                <video 
                  controls 
                  class="w-100 rounded" 
                  :poster="item['thumb_tablet_' + theme]">
                  <source :src="item['video_tablet_' + theme]" type="video/mp4">
                  Ваш браузер не поддерживает видео тег.
                </video>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button 
              @click="$router.push({ name: 'DemoPage' })"
              class="btn btn-primary me-2"
              data-bs-dismiss="modal">
              Документация
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="refreshModal" tabindex="-1" ref="refreshModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Доступна новая версия</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>
              <strong>Сайт</strong><br>
              Закройте все вкладки в браузерах и перезапустите сайт.
              Возможно придется перезапустить браузер.
              Удаление кэша тоже возможно поможет вам если не удались предыдущие шаги.
            </p>
            <p>
              <strong>Android / iOS</strong><br>
              Перезапустите. Если не помогло проверить открыт ли сайт в браузере. Если да то закрыть.
            </p>
            <p>
              <strong>Mini app / Web app в Телеграме</strong><br>Закройте приложение, перезапустите телеграм.
            </p>
            <p>
              <strong>ПК Mac</strong><br>
              Cmd + Shift + R
            </p>
            <p>
              <strong>ПК Windows</strong><br>
              Ctrl + Shift + R.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>

    <!-- data-bs-backdrop="static" data-bs-keyboard="false" -->
    <div class="modal" id="setRoleModal" tabindex="-1" ref="setRoleModal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header justify-content-center">
              <h5 class="modal-title">Выбрать роль для аккаунта</h5>
              <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
            </div>
            <div class="modal-body text-center">
              <div class=mb-3>
                <button @click="setRole('student')" class="btn btn-lg btn-secondary">Ученик</button>
              </div>
              <div class=mb-3>
                <button @click="setRole('admin')" class="btn btn-lg btn-secondary">Администратор</button>
              </div>
              <div class=mb-3>
                <button @click="setRole('instructor')" class="btn btn-lg btn-secondary">Инструктор</button>
              </div>
              <div>
                <button @click="setRole('supervisor')" class="btn btn-lg btn-secondary">Супервизор или куратор</button>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
            </div> -->
          </div>
        </div>
    </div>

    <div v-if="showRefresh" id="refresh-indicator"></div>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
      <div class="offcanvas-header">
        <!-- <h5 class="offcanvas-title" id="offcanvasMenuLabel">Документация</h5> -->
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <div>
          <button
            v-if="current_user.is_staff"
            @click="routeTo({name: 'UsersPage'})"
            class="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people text-secondary me-1" viewBox="0 0 16 16">
              <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
            </svg>
            Пользователи
          </button>
        </div>
        <div>
          <button
            v-if="current_user.is_staff"
            @click="routeTo({name: 'GroupsPage'})"
            class="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-vcard text-secondary me-1" viewBox="0 0 16 16">
              <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
              <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z"/>
            </svg>
            Группы
          </button>
        </div>
        <div>
          <button
            v-if="current_user.is_staff"
            @click="routeTo({name: 'TutorialsPage'})"
            class="btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-question text-secondary me-1" viewBox="0 0 16 16">
              <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.7 1.7 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745"/>
              <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z"/>
              <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0"/>
            </svg>
            Инструкции
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { apiErrorHandler } from '@/utils';
// import moment from 'moment'
import ThemeChangeComponent from './components/ThemeChangeComponent'
import NoSleep from 'nosleep.js'
import {Modal, Offcanvas} from 'bootstrap'
// import { useRoute } from 'vue-router'

export default {
  components: {
    ThemeChangeComponent,
  },
  data () {
    return {
      offcanvas: null,
      setRoleModal: null,
      helpModal: null,
      showHelpModal: false,
      containerHeight: 0,
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      voiceRecognize: false,
      subdomain: null,
      tg_user: null,
      userPages: [
        'UserIndexPage', 
        'UserConfigPage', 
        'UserSetsPage', 
        'UserSetPage', 
        'UserSetManagePage', 
        'UserCategoriesPage', 
        'UserTasksPage', 
        'UserTaskPage', 
        'UserChatPage', 
        'UserCardsPage', 
        'UserStatisticPage', 
        'UserEditPage', 
        'UserCardsManagePage', 
        'UserCardsAddPage', 
        'UserSubscriptionPage'
      ],
      help: false,
      instructions: [],
      showRefresh: false,
      registration: null,
      showRefreshInfo: false,
    }
  },
  // sockets: {
  //   connect: function () {
  //     console.log('App socket connected')
  //   },
  //   reconnect: function () {
  //     console.log('App socket reconnected')
  //   },
  //   play_audio: function (data) {
  //     this.playAudio(data.url)
  //   },
  //   notification: function ({ status, message }) {
  //     if (status === 'success') {
  //       this.$toast.success(message)
  //     } else {
  //       this.$toast.error(message)
  //     }
  //   },
  // },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.getTutorials();
      },
    },
  },
  computed: {
    theme () {
      return this.$store.state.config.theme
    },
    current_user () {
      return this.$store.state.current_user
    },
  },
  methods: {
    routeTo (route) {
      this.offcanvas.hide()
      this.$router.push(route)
    },
    async logout () {
      this.$store.dispatch('getToken');
      try {
        const response = await fetch(`/api/logout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.$store.state.csrftoken
          },
        })
        if (response.status === 200) {
          location.replace('/')
        } else {
          apiErrorHandler(this, response)
        }
      } catch (e) {
        this.$toast.error(`${e.name}: ${e.message}`)
      }
    },
    async setRole (role) {
      this.$store.dispatch('getToken');
      try {
        const body = {profile: { role }}
        const response = await fetch(`/api/users/${this.$store.state.user.id}/update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': this.$store.state.csrftoken
          },
          body: JSON.stringify(body),
        })
        if (response.status === 200) {
          this.$toast.success('Роль успешно обновлена')
          this.getUser()
          this.setRoleModal.hide()
        } else {
          apiErrorHandler(this, response)
        }
      } catch (e) {
        this.$toast.error(`${e.name}: ${e.message}`)
      }
    },
    clickHelp () {
      console.log('this.$route.name', this.$route.name)
      if (this.$route.name === 'UserSetPage' || this.$route.name === 'UserCardsPage') {
        if (this.instructions) {
          this.$router.push({ name: 'DemoPage', query: { id: this.instructions[0].id } })
        } else {
          this.$router.push({ name: 'DemoPage' })
        }
      } else {
        this.helpModal.show()
      }
    },
    async getTutorials () {
      try {
        // const pathname = window.location.pathname
        const pathname = this.$route.name
        if (!pathname) {
          return
        }
        const response = await fetch(`/api/tutorials?page=${pathname}`)
        if (response.status === 200) {
          const data = await response.json()
          if (data.items) {
            this.instructions = data.items
          }
        }
      } catch (e) {
        this.$toast.error(`${e.name}: ${e.message}`)
      }
    },
    async onTelegramAuth(data) {
      console.log('onTelegramAuth', data);
      this.tg_user = data
      const url = new URL('https://app.govori.site/api/auth/telegram?' + data);
      try {
        const response = await fetch(url);
        const responseData = await response.json();
        console.log(responseData);
        if (response.user) {
          this.$store.commit('mergeStore', { user: response.user })
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    addTgScript () {
      const script = document.createElement('script')
      script.src = 'https://telegram.org/js/telegram-widget.js?22'
      script.setAttribute('async', '')
      script.setAttribute('data-telegram-login', 'govori_site_bot')
      script.setAttribute('data-size', 'medium')
      script.setAttribute('data-radius', '8')
      script.setAttribute('data-auth-url', 'https://app.govori.site/api/auth/telegram')
      script.setAttribute('data-request-access', 'write')
      this.$refs.telegramLoginButton.appendChild(script)
    },
    fullScreenChange () {
      this.$store.dispatch('checkFullScreen')
    },  
    
    back () {
      // if (window.history.length > 1) {
      //   this.$router.go(-1)
      // } else {
      //   this.$router.push({name: 'IndexPage'})
      // }
      // if (['UserPage', 'GroupPage'].includes(this.$route.name)) {
        // this.$router.go(-1)
        // this.$router.push({name: 'UsersPage'})
      // } else if (['GroupPage'].includes(this.$route.name)) {
      //   this.$router.push({name: 'GroupsPage'})
      // } else if (['GroupEditPage'].includes(this.$route.name)) {
      if (['GroupEditPage'].includes(this.$route.name)) {
        this.$router.push({name: 'GroupPage', params: {id: this.$route.params.id}})
      } else {
        this.$router.push({name: 'IndexPage'})
      }
    },
    playAudio (url) {
      const audio = new Audio(url)
      return new Promise(res => {
        audio.play()
        audio.onended = res
      })
    },

    refreshApp() {
      this.showRefreshInfo = !this.showRefreshInfo;
      console.log('refreshApp', this.registration, this.registration.waiting)
      if (this.registration && this.registration.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        this.registration.waiting.addEventListener('statechange', (e) => {
          if (e.target.state === 'activated') {
            window.location.reload();
          }
        });
      }
    },
    handleSWUpdate(event) {
      this.registration = event.detail;
      this.showRefresh = true;
      // console.log('handleSWUpdate', event)
    },

    async getUser () {
      try {
        const response = await fetch(`/api/me`)
        const data = await response.json()
        if (data && data.user) {
          this.$store.commit('mergeStore', {
            current_user: data.user,
            user: data.user,
            config: data.config,
            sets: data.sets || [],
            groups: data.groups || []
          })
          if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
            console.log('Config theme not apply')
          } else {
            document.documentElement.setAttribute('data-bs-theme', data.config.theme)
          }
          if (!data.user.profile.role) {
            // alert('Выбрать роль')
            this.setRoleModal = new Modal(document.getElementById('setRoleModal'));
            this.setRoleModal.show()
          }
        }
      } catch (e) {
        this.$toast.error(`${e.name}: ${e.message}`)
      }
    },

    setContainerHeight() {
      this.containerHeight = window.innerHeight;
    },
    setupModalEventListeners() {
      const helpModalElement = document.getElementById('helpModal');
      
      helpModalElement.addEventListener('shown.bs.modal', () => {
        console.log('this.helpModal.addEventListener shown.bs.modal');
        this.showHelpModal = true;
      });

      helpModalElement.addEventListener('hidden.bs.modal', () => {
        console.log('this.helpModal.addEventListener hidden.bs.modal');
        this.showHelpModal = false;
      });
    }
  },
  created() {
    document.addEventListener('swUpdated', this.handleSWUpdate, { once: true });
  },
  async mounted () {
    try {
      this.setContainerHeight();
      window.addEventListener('resize', this.setContainerHeight);

      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
        this.onTelegramAuth(window.Telegram.WebApp.initData)
        this.$store.commit('mergeStore', {webView: true})
      } else {
        console.log('Telegram.WebApp not found')
        this.addTgScript()
      }

      // this.addTgScript()
      window.onTelegramAuth = this.onTelegramAuth;

      const url = window.location.hostname;
      this.subdomain = url.split('.')[0]

      this.noSleep = new NoSleep()
      window.noSleep = this.noSleep

      this.getUser()

      this.helpModal = new Modal(document.getElementById('helpModal'));
      this.$store.commit('mergeStore', { helpModal: this.helpModal });
      this.setupModalEventListeners();

      this.offcanvas = Offcanvas.getOrCreateInstance('#offcanvasMenu');

    } catch (e) {
      this.$toast.error(`${e.type}: ${e.message}`, {position: 'top'})
    }
  },
  beforeUnmount () {
    delete window.onTelegramAuth;
    window.removeEventListener('resize', this.setContainerHeight);
  },
}
</script>

<style lang="scss">
// @import "~bootstrap/scss/_variables.scss";

$gray-900: #212529;
$offcanvas-horizontal-width: 300px;
$enable-negative-margins: true;

$breadcrumb-font-size: 12px;
$breadcrumb-divider: '·';
$breadcrumb-item-padding-x: .25rem;

// $btn-border-radius: .5rem;
$btn-border-radius-sm: .3125rem;
$btn-padding-y-sm: .25rem;
$btn-padding-x-sm: .5rem;
$btn-font-size-sm: .75rem;

$nav-link-padding-y: .25rem;
$nav-link-padding-x: .5rem;
$nav-link-font-size: .75rem;
$nav-link-color: var(--bs-secondary-color);
$nav-pills-link-active-bg: var(--bs-secondary-bg-subtle);
$nav-pills-link-active-color: var(--bs-secondary-color);
// $nav-link-font-weight:              null !default;

$pagination-color: var(--bs-secondary-color);
$pagination-padding-x-sm: .625rem;
$pagination-active-color: var(--bs-secondary-color);
$pagination-active-bg: var(--bs-secondary-bg-subtle);
$pagination-active-border-color: var(--bs-secondary-bg-subtle);

@import "~bootstrap/scss/bootstrap";
@import "@/assets/extra.scss";

body{
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  user-select: none;
  overflow-y: scroll;
  // cursor: none;
}
main{
  // min-height: 100vh;

}

.nav-link.dropdown-toggle::before, .nav-link.dropdown-toggle::after {
  display: none;
}


.hover-pointer:hover{
  cursor: pointer;
}

#menu-btn{
  .dropdown-toggle::after{
    display: none;
  }
}

.btn-control{
  cursor: pointer;
  svg{
    color: white;
    width: 30px;
    height: 30px;
    @media (min-width: 340px){
      width: 40px;
      height: 40px;
    }
    @media (min-width: 768px) and (min-height: 450px) {
      width: 60px;
      height: 60px;
    }
  }
  transition: background 0.3s;
  &:hover{
    transition: background 0.3s;
  }
}

.btn{
  svg{
    margin-top: -4px;
  }
}
.btn-sm{
  svg{
    margin-top: -2px;
  }
}

html[data-bs-theme="dark"]{
  body, #_app_{
    --bd-violet-rgb: 112.520718,44.062154,249.437846;
    --bd-accent-rgb: 255,228,132;
    --bd-pink-rgb: 214,51,132;
    // background-image: linear-gradient(180deg, rgba(var(--bs-body-bg-rgb), 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%),radial-gradient(ellipse at top left, rgba(var(--bs-primary-rgb), 0.5), transparent 50%),radial-gradient(ellipse at top right, rgba(var(--bd-accent-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center right, rgba(var(--bd-violet-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center left, rgba(var(--bd-pink-rgb), 0.5), transparent 50%);
    // background-color: rgb(22, 29, 38);
    // background-image: url(@/assets/img/chat-bg-pattern-light.png);
    background-image: url(@/assets/img/pattern-d.png);
    mix-blend-mode: unset;
    background-position: top right;
    background-size: 400px auto;
    background-repeat: repeat;
  }
}
html[data-bs-theme="light"]{
  body, #_app_{
    // --bd-accent-rgb: #ffe484;
    // --bd-violet-rgb: #712cf9;
    // --bd-pink-rgb: 214,51,132;
    // background-image: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% );
    // background-image: linear-gradient(180deg, rgba(var(--bs-body-bg-rgb), 0.01), rgba(var(--bs-body-bg-rgb), 1) 85%),radial-gradient(ellipse at top left, rgba(var(--bs-primary-rgb), 0.5), transparent 50%),radial-gradient(ellipse at top right, rgba(var(--bd-accent-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center right, rgba(var(--bd-violet-rgb), 0.5), transparent 50%),radial-gradient(ellipse at center left, rgba(var(--bd-pink-rgb), 0.5), transparent 50%);
    background-color: var(--bs-light);
    // background-image: url(@/assets/img/chat-bg-pattern-dark.png);
    background-image: url(@/assets/img/pattern-l.png);
    background-size: 400px auto;
    background-position: top right;
    background-repeat: repeat;
    // mix-blend-mode: overlay;
    // background-image: url(@/assets/img/chat-bg-pattern-light.png), url(@/assets/img/chat-bg-br.png);
    // background-size: 510px auto, cover;
    // background-position: top right, center;
    // background-repeat: repeat, no-repeat;
    // mix-blend-mode: overlay, normal;
  }
}

.btn-add-card{
  background: #ccc;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  cursor: pointer;
  svg{
    width: 50px;
    height: 50px;
    color: white;
  }
  transition: background 0.3s;
  &:hover{
    background: #bbb;
    transition: background 0.3s;
  }
}

@media (min-width: 768px) and (min-height: 450px) {
  .btn-add-card{
    width: 150px;
    height: 150px;
    svg{
      width: 75px;
      height: 75px;
    }
  }
}

.link-to-back{
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-bottom-right-radius: 10px;
  backdrop-filter: blur(10px);
  svg {
    path{
      fill: gray;
    }
  }
}

.bg-blur{
  backdrop-filter: blur(5px);
}

.categories-item{
  // width: 120px;
  font-size: 12px;
  svg{
    height: 25px;
    width: 25px;
  }
}

.link-to:hover, .card-item{
  cursor: pointer;
}

.spinner-border{
  position: fixed;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.click-effect::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: clickEffect 0.5s ease-in-out;
}

@keyframes clickEffect {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
}


.refresh-notification {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color:rgba(255, 109, 121, 0.452);
  color: #721c24;
  text-align: center;
  padding: 1rem;
}

#refresh-indicator{
  position: fixed;
  top: 5px;
  right: 5px;
  width: 4px;
  height: 4px;
  background-color: var(--bs-primary);
  border-radius: 50%;
  z-index: 1100;
}


.separator {
  display: block;
  height: 0;
  &.separator-dashed{
    border-bottom-style: dashed;
    border-bottom-color: var(--bs-border-color-translucent);
  }
}
</style>
